import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import DataGrid from '../../components/DataGrid';
import financialsColumns from './FinancialsDatagridModel';
import {fetchFinancials, deleteFinancials, changeFinancialsbyId, createFinancials, changeFinancialsValidate} from '../../services/financials';
import {maskInput, createOrUpdateDataFull, formatDateTime} from '../../utils/utils';
import {MASKS} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {useModuleNameContextUpdate} from '../../utils/Providers/ModuleNameProvider';
import financialModuleMap from './FinancialModuleMap';

const FinancialsGrid = ({match}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  // const [generalTotal, setGeneralTotal] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);

  // Update ModuleName
  const updateModuleName = useModuleNameContextUpdate();
  const financialModuleName = financialModuleMap(match.params.slug);
  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    /* console.log(dataGridRows)
    const promises = [];
    dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, createFinancials, changeFinancialsbyId));
    }); */
    setIsLoading(true);

    const list = dataGridRows.map((item) => item.id);

    // eslint-disable-next-line
    const res = await changeFinancialsValidate(checked, list);

    const promises = [];
    const response = await Promise.all(promises);
    setUpdate(!update);
    return response;
  };

  useEffect(() => {
    const getContents = async () => {
      try {
        setIsLoading(true);
        const response = await fetchFinancials({
          type: match.params.slug.toUpperCase(),
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setData(response.data.resultList);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
    updateModuleName(financialModuleName);
  }, [update, stateFetchParams]);

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteFinancials(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (!dataGridInputs.date) {
              const date = formatDateTime(new Date());
              Object.assign(dataGridInputs, {date});
            }
            Object.assign(dataGridInputs, {
              status: false,
              type: match.params.slug.toUpperCase(),
            });
            const res = await createOrUpdateDataFull(dataGridInputs, state, createFinancials, changeFinancialsbyId);
            setUpdate((prev) => !prev);
            return res;
          }}
          header={financialsColumns({costCenter: match.params.slug}, {user: state.user}, state)}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
          validateCallback={validateHeaderCallback}
          accumulators={{
            'Total geral': {
              columns: ['value'],
              itemOperation: (acc, line, key) => {
                return (acc += line[key]);
              },
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.MONEY});
              },
            },
          }}
        />
      )}
      ,
      {isLoading && (
        <div className="overlay">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default FinancialsGrid;
