import apiNew from '../apiNew';
import api from '../api';

// eslint-disable-next-line import/prefer-default-export
export const fetchChecklistSyntheticData = async (params = {}) => {
  return apiNew.get('/checklist/synthetic', {params});
};

export const fetchChecklistAnalyticalData = async (params = {}, responseType = {arraybuffer: 'arraybuffer'}) => {
  return api.get('/checklist/analytical', {params, ...responseType});
};
